<template>
  <div>
    <v-card>
      <v-card-title>予約商品（編集）</v-card-title>
      <template>
        <v-spacer></v-spacer>
        <template v-if="apierror.status == 'error'">
          <div v-for="msg in apierror.messages" :key="msg">
            <v-row class="ml-6 mb-3 ma-3">
              <span style="color: red">* {{ msg }} </span>
            </v-row>
          </div>
        </template>
        <v-stepper v-model="page">
          <v-stepper-header>
            <v-stepper-step
              :complete="page > 1"
              step="1"
            >
              商品情報
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              :complete="page > 2"
              step="2"
            >
              概要
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="3">
              説明
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <template>
                <v-form class="ma-6 mt-0">
                  <v-row align="center">
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <label for="reserve_id">予約商品ID</label>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <label for="reserve_id">{{ Form.po_code }}({{ Form.po_id }})</label>
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <label for="pellet_id">ペレットID<span style="color: red">*</span></label>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-autocomplete
                        id="pellet_id"
                        v-model="$v.Form.pellet_id.$model"
                        :error-messages="pelletIdErrors"
                        :items="pelletIds"
                        outlined
                        clearable
                        dense
                        placeholder="ペレットID"
                        hide-details="auto"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <label for="reserve_name">商品名(日本語)<span style="color: red">*</span></label>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        id="reserve_id"
                        v-model="$v.Form.po_name_ja.$model"
                        outlined
                        dense
                        placeholder="商品名(日本語)"
                        :error-messages="nameJaErrors"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <label for="reserve_name">商品名(中国語)<span style="color: red">*</span></label>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        id="po_name_cn"
                        v-model="$v.Form.po_name_cn.$model"
                        :error-messages="nameCnErrors"
                        outlined
                        dense
                        placeholder="商品名(中国語)"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <label for="reserve_name">商品名(英語)<span style="color: red">*</span></label>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        id="po_name_en"
                        v-model="$v.Form.po_name_en.$model"
                        :error-messages="nameEnErrors"
                        outlined
                        dense
                        placeholder="予約商品名(英語)"
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <label for="reserve_weight">商品重量<span style="color: red">*</span></label>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <NumberInput
                        v-model="Form.po_weight"
                        :error-messages="weightErrors"
                        placeholder="商品重量"
                        :outlined="true"
                        suffix="KG/FCB"
                      ></NumberInput>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <label for="po_price">単価(JPY)<span style="color: red">*</span></label>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <NumberInput
                        v-model="Form.po_price"
                        :outlined="true"
                        :error-messages="PriceErrors"
                        prefix="¥"
                        suffix="/KG"
                      ></NumberInput>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <label for="po_price_usd">単価(USD)<span style="color: red">*</span></label>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <NumberInput
                        v-model="Form.po_price_usd"
                        :outlined="true"
                        :error-messages="PriceUsdErrors"
                        prefix="$"
                        suffix="/ton"
                      ></NumberInput>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <label for="reserve_maximum">上限<span style="color: red">*</span></label>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <v-text-field
                        id="po_max_qty"
                        v-model="$v.Form.po_max_qty.$model"
                        :error-messages="maximumErrors"
                        type="number"
                        outlined
                        dense
                        placeholder="上限"
                        hide-details="auto"
                        @click="handleClick($event)"
                      ></v-text-field>
                    </v-col>
                    <!-- <v-col cols="12" md="9">
                      <label>上限数量をいれてください。0：無制限</label>
                    </v-col> -->
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <label for="reseve_open_dt">予約開始日<span style="color: red">*</span></label>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        :nudge-right="40"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="$v.Form.po_ssd.$model"
                            :error-messages="openDtErrors"
                            outlined
                            dense
                            placeholder="予約開始日"
                            hide-details="auto"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="Form.po_ssd"
                          @input="menu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <label for="firstname">予約締切日<span style="color: red">*</span></label>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        :nudge-right="40"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="$v.Form.po_sed.$model"
                            :error-messages="compDtErrors"
                            outlined
                            dense
                            placeholder="予約締切日"
                            hide-details="auto"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="Form.po_sed"
                          :allowed-dates="allowedDates"
                          @input="menu1 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="2"
                      align-self="center"
                    >
                      <span>イメージ</span>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <AddMedia
                        :show-btn="false"
                        @exportFile="insertImgs"
                      ></AddMedia>
                    </v-col>
                  </v-row>
                  <v-row v-if="files.length !== 0" class="ma-0">
                    <draggable
                      v-model="files"
                      class="d-flex flex-row flex-wrap"
                      style="height:100%;width:100%"
                      animation="300"
                    >
                      <v-col
                        v-for="(item, i) in files"
                        :key="i"
                        cols="2"
                        @contextmenu.prevent="openMenu($event,i)"
                      >
                        <v-card
                          style="border: 1px solid #CFD8DC"
                          flat
                          tile
                          outlined
                          color="#fafafa"
                          height="100%"
                        >
                          <v-img
                            :src="`${Form.imgUrl_prefix ? Form.imgUrl_prefix : ''}/${item.replace(/^\//g, '')}`"
                            height="120px"
                            contain
                          >
                          </v-img>
                        </v-card>
                      </v-col>
                    </draggable>
                  </v-row>
                  <div v-else>
                    <div class="d-flex justify-center align-center my-4">
                      <v-img
                        src="@/assets/images/noimage.jpg"
                        max-width="120"
                      >
                      </v-img>
                    </div>
                    <div class="d-flex justify-center align-center my-4">
                      <span>[ + ] ボタンを使用して、さらにイメージを追加します。</span>
                    </div>
                  </div>
                </v-form>
              </template>
              <v-card-actions
                align="center"
                class="d-flex justify-center"
              >
                <v-btn
                  color="primary"
                  @click="toPage2(Form)"
                >
                  次へ
                </v-btn>
                <v-btn
                  outlined
                  @click="cancelClick"
                >
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-stepper-content>

            <v-stepper-content step="2">
              <template>
                <v-form class="ma-6">
                  <v-row
                    no-gutters
                    class="mt-5"
                  >
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <span
                        style="font-size:16px;color:black"
                      >概要(日本語)</span>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <div class="d-flex align-center flex-wrap">
                        <v-btn
                          color="#4682B4"
                          elevation="0"
                          class="mb-4 me-3 mt-2"
                          @click="onEditorStatusOvvJa"
                        >
                          <span style="color:white">編集の表示/非表示</span>
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <ckeditor
                        v-if="showEditorOvvJa"
                        v-model="$v.Form.po_ovv_ja.$model"
                        :editor="editor"
                        :config="editorConfig"
                      ></ckeditor>
                      <v-textarea
                        v-else
                        v-model="$v.Form.po_ovv_ja.$model"
                        :error-messages="poOvvJaErrors"
                        hide-details="auto"
                        outlined
                        :counter="1000"
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                  <v-row
                    no-gutters
                    class="mt-5"
                  >
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <span
                        style="font-size:16px;color:black"
                      >概要(中国語)</span>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <div class="d-flex align-center flex-wrap">
                        <v-btn
                          color="#4682B4"
                          elevation="0"
                          class="mb-4 me-3 mt-2"
                          @click="onEditorStatusOvvCn"
                        >
                          <span style="color:white">編集の表示/非表示</span>
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <ckeditor
                        v-if="showEditorOvvCn"
                        v-model="$v.Form.po_ovv_cn.$model"
                        :editor="editor"
                        :config="editorConfig"
                      ></ckeditor>
                      <v-textarea
                        v-else
                        v-model="$v.Form.po_ovv_cn.$model"
                        :error-messages="poOvvCnErrors"
                        hide-details="auto"
                        outlined
                        :counter="1000"
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                  <v-row
                    no-gutters
                    class="mt-5"
                  >
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <span
                        style="font-size:16px;color:black"
                      >概要(英語)</span>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <div class="d-flex align-center flex-wrap">
                        <v-btn
                          color="#4682B4"
                          elevation="0"
                          class="mb-4 me-3 mt-2"
                          @click="onEditorStatusOvvEn"
                        >
                          <span style="color:white">編集の表示/非表示</span>
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <ckeditor
                        v-if="showEditorOvvEn"
                        v-model="$v.Form.po_ovv_en.$model"
                        :editor="editor"
                        :config="editorConfig"
                      ></ckeditor>
                      <v-textarea
                        v-else
                        v-model="$v.Form.po_ovv_en.$model"
                        :error-messages="poOvvEnErrors"
                        hide-details="auto"
                        outlined
                        :counter="1000"
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-form>
              </template>
              <v-card-actions
                align="center"
                class="d-flex justify-center"
              >
                <v-btn
                  color="primary"
                  @click="toPage3(Form)"
                >
                  次へ
                </v-btn>
                <v-btn
                  text
                  outlined
                  @click="page = 1"
                >
                  戻る
                </v-btn>
                <v-btn
                  outlined
                  @click="cancelClick"
                >
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-stepper-content>

            <v-stepper-content step="3">
              <template>
                <v-form class="ma-6">
                  <v-row
                    no-gutters
                    class="mt-5"
                  >
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <span
                        style="font-size:16px;color:black"
                      >説明(日本語)</span>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <div class="d-flex align-center flex-wrap">
                        <v-btn
                          color="#4682B4"
                          elevation="0"
                          class="mb-4 me-3 mt-2"
                          @click="onEditorStatusDescJa"
                        >
                          <span style="color:white">編集の表示/非表示</span>
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <ckeditor
                        v-if="showEditorDescJa"
                        v-model="$v.Form.po_desc_ja.$model"
                        :editor="editor"
                        :config="editorConfig"
                      ></ckeditor>
                      <v-textarea
                        v-else
                        v-model="$v.Form.po_desc_ja.$model"
                        :error-messages="poDescJaErrors"
                        hide-details="auto"
                        outlined
                        :counter="1000"
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                  <v-row
                    no-gutters
                    class="mt-5"
                  >
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <span
                        style="font-size:16px;color:black"
                      >説明(中国語)</span>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <div class="d-flex align-center flex-wrap">
                        <v-btn
                          color="#4682B4"
                          elevation="0"
                          class="mb-4 me-3 mt-2"
                          @click="onEditorStatusDescCn"
                        >
                          <span style="color:white">編集の表示/非表示</span>
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <ckeditor
                        v-if="showEditorDescCn"
                        v-model="$v.Form.po_desc_cn.$model"
                        :editor="editor"
                        :config="editorConfig"
                      ></ckeditor>
                      <v-textarea
                        v-else
                        v-model="$v.Form.po_desc_cn.$model"
                        :error-messages="poDescCnErrors"
                        hide-details="auto"
                        outlined
                        :counter="1000"
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                  <v-row
                    no-gutters
                    class="mt-5"
                  >
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <span
                        style="font-size:16px;color:black"
                      >説明(英語)</span>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <div class="d-flex align-center flex-wrap">
                        <v-btn
                          color="#4682B4"
                          elevation="0"
                          class="mb-4 me-3 mt-2"
                          @click="onEditorStatusDescEn"
                        >
                          <span style="color:white">編集の表示/非表示</span>
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <ckeditor
                        v-if="showEditorDescEn"
                        v-model="$v.Form.po_desc_en.$model"
                        :editor="editor"
                        :config="editorConfig"
                      ></ckeditor>
                      <v-textarea
                        v-else
                        v-model="$v.Form.po_desc_en.$model"
                        :error-messages="poDescEnErrors"
                        hide-details="auto"
                        outlined
                        :counter="1000"
                        dense
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-form>
              </template>
              <v-card-actions
                align="center"
                class="d-flex justify-center"
              >
                <v-btn
                  color="primary"
                  :loading="submitStatus"
                  @click="submit(Form)"
                >
                  Submit
                </v-btn>
                <v-btn
                  text
                  outlined
                  @click="page = 2"
                >
                  戻る
                </v-btn>
                <v-btn
                  outlined
                  @click="cancelClick"
                >
                  Cancel
                </v-btn>
              </v-card-actions>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </template>
    </v-card>
    <media-edit-menu
      :visible="visible"
      :left="left"
      :top="top"
      @openConfirmDelete="deleteFile"
    ></media-edit-menu>
  </div>
</template>

<script>
import Editor from 'ckeditorplugin'
import {
  required, maxLength, numeric, minValue,
} from 'vuelidate/lib/validators'
import {
  mdiPlus, mdiCalendar,
} from '@mdi/js'
import {
  mapActions, mapState, mapMutations,
} from 'vuex'
import AddMedia from '@/components/AddMedia.vue'
import MediaEditMenu from '@/components/MediaEditMenu.vue'
import NumberInput from '@/components/Input/NumberInput.vue'

export default {
  components: {
    NumberInput,
    AddMedia,
    MediaEditMenu,
  },
  data: () => ({
    icons: {
      mdiPlus,
      mdiCalendar,
    },
    page: 1,
    submitStatus: false,
    rightClickIndex: '',
    changeFlag: false,
    menu: false,
    menu1: false,
    apierror: [],
    Form: {
      pellet_id: '',
      po_id: '',
      po_name_ja: '',
      po_name_cn: '',
      po_name_en: '',
      po_weight: 0,
      po_max_qty: 10,
      po_price: '',
      po_price_usd: '',
      imgUrl_prefix: '',
      images: '',
      po_ssd: '',
      po_sed: '',
      po_images: '',
      po_ovv_ja: '',
      po_ovv_cn: '',
      po_ovv_en: '',
      po_desc_ja: '',
      po_desc_cn: '',
      po_desc_en: '',

    },
    showEditorOvvJa: true,
    showEditorOvvCn: true,
    showEditorOvvEn: true,
    showEditorDescJa: true,
    showEditorDescCn: true,
    showEditorDescEn: true,
    files: [],
    visible: false,
    top: 0,
    left: 0,
    editor: Editor,
    editorData: '<p>Content of the editor.</p>',
    editorConfig: {
      // The configuration of the editor.
    },
    editorValue: '1',
  }),
  validations: {
    Form: {
      pellet_id: {
        required,
      },

      po_name_ja: {
        required,
        maxLength: maxLength(100),
      },
      po_name_cn: {
        required,
        maxLength: maxLength(100),
      },
      po_name_en: {
        required,
        maxLength: maxLength(100),
      },

      po_weight: {
        required,
        numeric,
        minValue: minValue(1),
      },
      po_price: {
        required,
        numeric,
        minValue: minValue(1),
      },
      po_price_usd: {
        required,
        numeric,
        minValue: minValue(1),
      },
      po_max_qty: {
        required,
        numeric,
        minValue: minValue(1),
      },
      po_ssd: {
        required,
      },
      po_sed: {
        required,
      },

      po_ovv_ja: {
        maxLength: maxLength(1000),
      },
      po_ovv_cn: {
        maxLength: maxLength(1000),
      },
      po_ovv_en: {
        maxLength: maxLength(1000),
      },
      po_desc_ja: {
        maxLength: maxLength(1000),
      },
      po_desc_cn: {
        maxLength: maxLength(1000),
      },
      po_desc_en: {
        maxLength: maxLength(1000),
      },
    },
  },
  computed: {
    ...mapState('pelletStore', ['pelletIds']),
    ...mapState('preorderStore', ['selectedPreorder']),

    nameJaErrors() {
      const errors = []
      if (!this.$v.Form.po_name_ja.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.po_name_ja.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.po_name_ja.maxLength && errors.push('100文字以内にして下さい。')

      return errors
    },

    nameCnErrors() {
      const errors = []
      if (!this.$v.Form.po_name_cn.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.po_name_cn.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.po_name_cn.maxLength && errors.push('100文字以内にして下さい。')

      return errors
    },

    nameEnErrors() {
      const errors = []
      if (!this.$v.Form.po_name_en.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.po_name_en.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.po_name_en.maxLength && errors.push('100文字以内にして下さい。')

      return errors
    },
    pelletIdErrors() {
      const errors = []
      if (!this.$v.Form.pellet_id.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.pellet_id.required && errors.push('必須項目')

      return errors
    },
    weightErrors() {
      const errors = []
      if (!this.$v.Form.po_weight.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.po_weight.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.po_weight.numeric && errors.push('必須数字')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.po_weight.minValue && errors.push('0以外にしてください')

      return errors
    },
    PriceErrors() {
      const errors = []
      if (!this.$v.Form.po_price.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.po_price.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.po_price.numeric && errors.push('必須数字')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.po_price.minValue && errors.push('0以外にしてください')

      return errors
    },
    PriceUsdErrors() {
      const errors = []
      if (!this.$v.Form.po_price_usd.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.po_price_usd.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.po_price_usd.numeric && errors.push('必須数字')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.po_price_usd.minValue && errors.push('0以外にしてください')

      return errors
    },
    maximumErrors() {
      const errors = []
      if (!this.$v.Form.po_max_qty.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.po_max_qty.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.po_max_qty.numeric && errors.push('必須数字')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.po_max_qty.minValue && errors.push('0以外にしてください')

      return errors
    },
    openDtErrors() {
      const errors = []
      if (!this.$v.Form.po_ssd.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.po_ssd.required && errors.push('必須項目')

      return errors
    },
    compDtErrors() {
      const errors = []
      if (!this.$v.Form.po_sed.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.po_sed.required && errors.push('必須項目')

      return errors
    },
    poOvvJaErrors() {
      const errors = []
      if (!this.$v.Form.po_ovv_ja.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.po_ovv_ja.maxLength && errors.push('1000文字以内にして下さい。')

      return errors
    },
    poOvvCnErrors() {
      const errors = []
      if (!this.$v.Form.po_ovv_cn.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.po_ovv_cn.maxLength && errors.push('1000文字以内にして下さい。')

      return errors
    },
    poOvvEnErrors() {
      const errors = []
      if (!this.$v.Form.po_ovv_en.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.po_ovv_en.maxLength && errors.push('1000文字以内にして下さい。')

      return errors
    },
    poDescJaErrors() {
      const errors = []
      if (!this.$v.Form.po_desc_ja.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.po_desc_ja.maxLength && errors.push('1000文字以内にして下さい。')

      return errors
    },
    poDescCnErrors() {
      const errors = []
      if (!this.$v.Form.po_desc_cn.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.po_desc_cn.maxLength && errors.push('1000文字以内にして下さい。')

      return errors
    },
    poDescEnErrors() {
      const errors = []
      if (!this.$v.Form.po_desc_en.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.Form.po_desc_en.maxLength && errors.push('1000文字以内にして下さい。')

      return errors
    },
  },
  watch: {
    Form: {
      handler() {
        this.changeFlag = true
      },
      deep: true,
    },
    'Form.po_ssd': {
      handler(newValue) {
        const sedValue = new Date(Date.parse(newValue))

        // 1ヶ月後
        const sedMonth = sedValue.setMonth(sedValue.getMonth() + 1)
        const sedDate = new Date(sedMonth).setDate(sedValue.getDate())
        this.Form.po_sed = new Date(sedDate).toISOString().substring(0, 10)
      },
    },
    visible(value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu)
      } else {
        document.body.removeEventListener('click', this.closeMenu)
      }
    },
    files: {
      handler(newValue) {
        this.Form.images = ''
        newValue.forEach((n, i) => {
          if (i === newValue.length - 1) {
            this.Form.images += `"${n}"`
          } else {
            this.Form.images += `"${n}",`
          }
        })
      },
    },
  },
  mounted() {
    this.loadData()
  },
  destoryed() {
    this.clearSelectedPreorder()
    this.clearPelletIds()
  },
  methods: {
    ...mapActions('pelletStore', ['loadPelletIds']),
    ...mapActions('preorderStore', ['editPreorder', 'loadPreorder']),
    ...mapMutations('pelletStore', ['clearPelletIds']),
    ...mapMutations('preorderStore', ['clearSelectedPreorder']),
    ...mapMutations('app', ['setOverlayStatus']),
    insertImgs(files) {
      if (files.length !== 0) {
        files.forEach(f => {
          this.files.push(f.url)
        })

        this.Form.imgUrl_prefix = files[0].full_url.replace(files[0].url, '').replace(/\/$/g, '')
        console.log('insertImgs', this.Form.imgUrl_prefix)
      }
    },

    openMenu(e, index) {
      this.rightClickIndex = index
      console.log('openMenu', this.rightClickIndex)

      const navMenu = document.getElementsByClassName('app-navigation-menu')[0].style.transform
      if (navMenu === 'translateX(0%)') {
        this.left = e.pageX - 268
      } else {
        this.left = e.pageX
      }
      this.top = e.pageY - 74

      this.visible = true
      console.log('openMenu', this.visible)
    },

    closeMenu() {
      this.visible = false
    },

    deleteFile() {
      this.files.splice(this.rightClickIndex, 1)
    },

    cancelClick() {
      if (this.changeFlag) {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('他ページに遷移します。よろしいでしょうか？')) {
          this.jumpToListPage()
        }

        return
      }
      this.jumpToListPage()
    },
    handleClick(event) {
      event.target.select()
    },
    onEditorStatusOvvJa() {
      this.showEditorOvvJa = !this.showEditorOvvJa
    },
    onEditorStatusOvvCn() {
      this.showEditorOvvCn = !this.showEditorOvvCn
    },
    onEditorStatusOvvEn() {
      this.showEditorOvvEn = !this.showEditorOvvEn
    },
    OvvFalse() {
      this.showEditorOvvJa = false
      this.showEditorOvvCn = false
      this.showEditorOvvEn = false
    },
    onEditorStatusDescJa() {
      this.showEditorDescJa = !this.showEditorDescJa
    },
    onEditorStatusDescCn() {
      this.showEditorDescCn = !this.showEditorDescCn
    },
    onEditorStatusDescEn() {
      this.showEditorDescEn = !this.showEditorDescEn
    },
    DescFalse() {
      this.showEditorDescJa = false
      this.showEditorDescCn = false
      this.showEditorDescEn = false
    },
    OvvDescObjChange() {
      if (this.Form.po_ovv_cn === undefined || this.Form.po_ovv_cn === null) {
        this.Form.po_ovv_cn = ''
      }
      if (this.Form.po_ovv_en === undefined || this.Form.po_ovv_en === null) {
        this.Form.po_ovv_en = ''
      }
      if (this.Form.po_ovv_ja === undefined || this.Form.po_ovv_ja === null) {
        this.Form.po_ovv_ja = ''
      }
      if (this.Form.po_desc_cn === undefined || this.Form.po_desc_cn === null) {
        this.Form.po_desc_cn = ''
      }
      if (this.Form.po_desc_en === undefined || this.Form.po_desc_en === null) {
        this.Form.po_desc_en = ''
      }
      if (this.Form.po_desc_ja === undefined || this.Form.po_desc_ja === null) {
        this.Form.po_desc_ja = ''
      }
    },
    insertCkEditor1(files) {
      let content = ''
      files.forEach(file => {
        content += `<img src="${file.full_url}" alt="${file.name}" />`
      })
      this.Form.reserve_images = content
    },
    jumpToListPage() {
      this.$router.push({
        path: '/preorder-list',
        query: this.$route.params.query ?? {
        },
      })
    },
    loadData() {
      this.setOverlayStatus(true)
      Promise.all([
        this.loadPelletIds(),
        this.loadPreorder(this.$route.params.id),
      ]).then(() => {
        this.Form = this.$deepCopy(this.selectedPreorder)
        this.OvvDescObjChange()
        if (this.selectedPreorder.images) {
          this.selectedPreorder.images.split(',').forEach(i => {
            this.files.push(i.replace(/^"+|"$/g, ''))
          })
        }
      }).catch(error => {
        if (error.response) {
          this.apierror.status = 'error'
          this.apierror.code = error.response.data.code
          this.apierror.messages = error.response.data.message
        }
      }).finally(() => {
        this.setOverlayStatus(false)
      })
    },
    toPage2(Form) {
      console.log('submitTo', Form)
      this.submitStatus = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.apierror.messages = []
        this.page = 2
        this.submitStatus = false
        window.scrollTo(0, 0)
      } else {
        console.log('error submit!!')
        window.scrollTo(0, 0)
        this.apierror.messages = []
        this.apierror.status = 'error'
        this.apierror.messages.push('赤い部分の情報を修正してください')
        this.submitStatus = false
      }
    },
    toPage3(Form) {
      console.log('submitTo', Form)
      this.submitStatus = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.apierror.messages = []
        this.page = 3
        this.submitStatus = false
        window.scrollTo(0, 0)
      } else {
        console.log('error submit!!')
        this.OvvFalse()
        window.scrollTo(0, 0)
        this.apierror.messages = []
        this.apierror.status = 'error'
        this.apierror.messages.push('赤い部分の情報を修正してください')
        this.submitStatus = false
      }
    },
    submit(Form) {
      this.submitStatus = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.$isObjectEqual(Form, this.selectedPreorder)) {
          console.log('isObjectEqual')
          this.jumpToListPage()
        } else {
          this.submitStatus = true
          this.editPreorder(Form)
            .then(() => {
              this.jumpToListPage()
            })
            .catch(error => {
              if (error.response) {
                this.apierror.status = 'error'
                this.apierror.code = error.response.data.code
                this.apierror.messages = error.response.data.message
              }
            }).finally(() => {
              this.submitStatus = false
            })
        }
      } else {
        console.log('error submit!!')
        this.DescFalse()
        window.scrollTo(0, 0)
        this.apierror.messages = []
        this.apierror.status = 'error'
        this.apierror.messages.push('赤い部分の情報を修正してください')
        this.submitStatus = false
      }
    },

    allowedDates(val) {
      return Date.parse(val) > Date.parse(this.Form.po_ssd)
    },
  },
}
</script>
